/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import { Component, ElementRef, HostListener, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { DataFilesDetails, SubmittedFilesData } from "../../model/DataFilesDetails";
import { UserInformation } from "../../model/UserInformation";
import { DashboardService } from "../../services/dashboard.service";
import { ApiService } from "../../services/api.service";
import { FileUploadService } from "../../services/file-upload.service";
import { Errors, FileStatus, UploadedFilesData, Warnings } from "../../model/UploadedFilesData";
import { EndpointsService } from "../../services/endpoints.service";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { SellerServiceNumberService } from "../../services/sellerservicernumber.service";
import { SellerServicerNumber } from '../../model/SellerServicerNumber';
import { AppConstants } from '../../shared/app.constants';
import { Utils } from "../../shared/utils";
import * as _ from 'lodash';
import { ConfirmationService } from '@fm-ui-adk/components-2.0/common/services';
import { DocWayDetailsService } from "../../services/docwaydetails.service";
import { SellerServicerData } from "../../model/SellerServicerData";
import { UserDetails } from "../../model/UserDetails";
import { CDXUploadedFilesList } from "../../model/CDXUploadedFilesList";
import { AcqnDashboardDetails, AcquisitionDetails, SelectedReports } from "../../model/AcquisitionDetails";
import { SelectItem } from '@fm-ui-adk/components-2.0/common/model';
import { LaunchDarklyUtil } from '../../shared/launch-darkly-util';

@Component({
  selector: 'app-data-standard',
  templateUrl: './dd-dashboard.component.html',
  styleUrls: ['./dd-dashboard.component.css'],
  providers: [DashboardService]
})

export class DD_DashboardComponent implements OnInit {
  @ViewChild('dialog1', { read: ElementRef }) dialogRef: ElementRef;

  display1: boolean = false;
  alertMessage: string;
  cols1: any[];
  cols2: any[];
  cols3: any[];
  cols3_bpo: any[];
  cols4: any[];
  dataFilesDetails: DataFilesDetails;
  dataFilesDetailsDashboardDisplay: SubmittedFilesData[] = [];
  dataFilesLenderList: any[] = [];
  dataFilesSelectedLender: string = "";
  userInfoArray: UserInformation[] = [];
  sellerServicerDataArray: SellerServicerData[] = [];
  roles: any[];
  userId: string;
  cfmlycode: string;
  uploadedFilesArray: UploadedFilesData[] = [];
  uploadedFilesArrayTemp: UploadedFilesData[] = [];
  submittedFilesArray: UploadedFilesData[] = [];
  selectedFilesArray: FileStatus[] = [];
  uploadedFilesArrayDisplay: FileStatus[] = [];
  dealId: string;
  selected: number;
  errors: Errors[] = [];
  warnings: Warnings[] = [];
  status: UploadedFilesData;
  showWarnings: boolean;
  showErrors: boolean;
  fileType: any[];
  originalName: string;
  newName: string;
  newNameSplit: any[];
  fileRejectedError: boolean;
  fileRejectedErrorWarning: boolean;
  fileWarning: boolean;
  row: number;
  masterRecord: boolean;
  public alertFeedback: any = [];
  sellerServicerNumber: SellerServicerNumber;
  lenderName: string;
  sellerServicerNoParent: string;
  userDetails: UserDetails;
  disableUpload: boolean = false;
  steps;
  currentStep;
  screenHeight: number;
  modalScreenHeight: number;
  modalTableHeight: string;
  screenWidth: number;
  modalScreenWidth: number;
  dataFilesFilterBinding: string;
  acquisitionsFilterBinding: string;
  lenderRankingFilterBinding: string;
  warningCount: Number;
  errorCount: Number;
  issueSummary: string;
  rowData;
  showIssueSummary: boolean;
  showIssueSummaryPreview: boolean;
  objectKey: string;
  selectedFileCount: number;
  isLoading: boolean = false;
  isLoadingDueDiligence: boolean = false;
  navigationLinks: any[];
  selectFilesTouched: boolean = false;
  finishAndCloseClicked: boolean = false;
  tableSubtitle: string;
  tableNoSubmittedFilesMessage: string;
  cdxUploadedFilesList: CDXUploadedFilesList[] = [];

  acqnDetails: AcquisitionDetails;
  currentMonth: number;

  tableNoAcqsnDealsMessage: string;
  dusGatewayDealSelectionSelectedItem: any[];

  showAcqnTableEditCheckbox: boolean = false;
  acqntbl: any = null;

  startPeriodSelectionText: string;
  endPeriodSelectionText: string;
  monthNames: any[];
  selectStartPeriod: any[] = [];
  selectEndPeriod: any[] = [];
  selectedStartPeriod: string = "";
  selectedEndPeriod: string = "";
  overviewDropdownMenu: any[] = [];
  selectedOverviewPeriod: string = "";
  selectedOverviewPeriodAPIFormat: string = "";
  monthDiff: number;
  monthDiffFutureDate: number;

  notice: any = null;

  dataFilesTabSelected: boolean = false;

  systemNotificationFlagValue = {};
  showSystemNotification = false;

  showTabLenderRanking: boolean = false;
  showTabAcquisitions: boolean = false;
  showTabDataFiles: boolean = false;

  //User flags
  isInternal: boolean = false;
  isAdminUser: boolean = false;
  isMFOpsUser: boolean = false;
  isAssetManager: boolean = false;
  isMAUser: boolean = false;

  constructor(@Inject(LOCALE_ID) public locale: string, private dashboardService: DashboardService, private router: Router,
    private apiService: ApiService, private fileUploadService: FileUploadService,
    private endpointsService: EndpointsService, private _http: HttpClient,
    private route: ActivatedRoute, private sellerServiceNumberService: SellerServiceNumberService,
    private confirmationService: ConfirmationService, private utils: Utils,
    private docWayDetailsService: DocWayDetailsService,
    private launchDarkly: LaunchDarklyUtil) {
    this.getScreenSize();
  }

  ngOnInit() {
    this.initLaunchDarkly();

    this.userId = this.apiService.getUserDetails().uid;
    this.cfmlycode = this.apiService.getUserDetails().corporateFamilyCode;
    this.roles = this.apiService.getUserDetails().groups;

    //Set MFOps/MFAssetManager/M&AUser Flags
    this.roles.forEach(role => {
      let roleName: String = role.toString();
      if ((roleName === "SG-GA6-MFDIGITIZER-MFOps-UAT-ROLE") ||
        (roleName === "SG-GA6-MFDIGITIZER-MFOps-PROD-ROLE")) {
        this.showAcqnTableEditCheckbox = true;
        this.isMFOpsUser = true;
      } else if ((roleName.indexOf('SG-GA6-MFDIGITIZER-UAT-MFASSETMGR-ROLE') == 0) ||
        (roleName.indexOf('SG-GA6-MFDIGITIZER-PROD-MFASSETMGR-ROLE') == 0)) {
        this.isAssetManager = true;
      } else if ((roleName.indexOf('SG-GA6-MFDIGITIZER-UAT-MA-ROLE') == 0) ||
        (roleName.indexOf('SG-GA6-MFDIGITIZER-PROD-MA-ROLE') == 0)) {
        this.isMAUser = true;
      } else if ((roleName.indexOf('SG-GA6-MFDIGITIZER-UAT-ReadWrite') == 0) ||
        (roleName.indexOf('SG-GA6-MFDIGITIZER-UAT-ReadOnly') == 0) ||
        (roleName.indexOf('SG-GA6-MFDIGITIZER-PROD-ReadWrite') == 0) ||
        (roleName.indexOf('SG-GA6-MFDIGITIZER-PROD-ReadOnly') == 0)) {
        this.isAdminUser = true;
      }
    });

    //set flags and variables
    this.selected = 0;
    this.fileUploadService.disableUpload = false;
    this.fileUploadService.rejectedFiles = [];
    this.showWarnings = false;
    this.showErrors = false;
    this.fileRejectedError = false;
    this.fileRejectedErrorWarning = false;
    this.fileWarning = false;
    this.masterRecord = false;
    this.showIssueSummary = false;
    this.showIssueSummaryPreview = false;
    this.tableSubtitle = AppConstants.DD_TABLE_SUBTITLE;
    this.tableNoSubmittedFilesMessage = AppConstants.DD_TABLE_NO_SUBMITTED_FILES_MESSAGE;

    //Gather details from docway
    this.docWayDetailsService.getDetails();

    this.showWarnings = false;
    this.showErrors = false;
    this.fileRejectedError = false;
    this.fileRejectedErrorWarning = false;
    this.fileWarning = false;
    this.masterRecord = false;

    //Acquisition Tab variables
    this.startPeriodSelectionText = AppConstants.START_PERIOD_SELECTION_TEXT;
    this.endPeriodSelectionText = AppConstants.END_PERIOD_SELECTION_TEXT;
    this.monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];

    let tempDate = new Date();

    this.monthDiff = this.calculateMonths(new Date(AppConstants.START_PERIOD_DD), tempDate);
    this.monthDiffFutureDate = 12;

    this.getDropDownDates();
    this.getStartPeriodDates();

    this.selectedStartPeriod = 'January ' + tempDate.getFullYear();

    this.getEndPeriodDates(true, false);
    this.getEndPeriodDates(true, true);

    //this.selectedEndPeriod = tempDate.toDateString();
    this.selectedEndPeriod = "";

    this.tableNoAcqsnDealsMessage = AppConstants.ACQSN_TABLE_NO_ACQSN_DEALS_MESSAGE;

    this.currentMonth = (new Date().getMonth() + 1);

    this.navigationLinks = [
      {
        href: '/',
        label: 'Home'
      },
      {
        href: '#',
        label: 'Due Diligence Reports'
      }
    ];

    this.cols1 = [
      { field: 'name', header: 'Username' },
      { field: 'ssn', header: 'Seller Servicer Number' },
      { field: 'org', header: 'Lender Name' }
    ];

    this.cols2 = [
      { field: 'mf_deal_id', header: 'Deal ID' },
      { field: 'mf_doc_type', header: 'Report Type' },
      { field: 'mfcd_deal_name', header: 'Deal Name' }
    ];

    this.cols3 = [
      { field: 'fileNM', header: 'File Name', width: '19%' },
      { field: 'reportType', header: 'Report Type', width: '9%' },
      { field: 'fileType', header: 'File Format', width: '9%' },
      { field: 'dusDealId', header: 'DUS Gateway Deal ID', width: '13%' },
      { field: 'acqnDealId', header: 'Acquisition Deal ID', width: '13%' },
      { field: 'collateralNBR', header: 'Collateral Reference #', width: '13%' },
      { field: 'fileSenderNM', header: 'Submitted By', width: '11%' },
      { field: 'submitDT', header: 'Submission On (EDT)', width: '13%' }
    ];

    this.cols3_bpo = [
      { field: 'fileNM', header: 'File Name', width: '20%' },
      { field: 'reportType', header: 'Report Type', width: '10%' },
      { field: 'fileType', header: 'File Format', width: '10%' },
      { field: 'collateralNBR', header: 'Collateral Reference #', width: '20%' },
      { field: 'fileSenderNM', header: 'Submitted By', width: '20%' },
      { field: 'submitDT', header: 'Submission On (EDT)', width: '20%' }
    ];

    this.cols4 = [
      { field: 'filename', header: 'File' },
      { field: 'status', header: 'Submission Eligibility' },
      { field: 'issueSummary', header: 'Issue Summary' },
      { field: 'receivedDT', header: 'Upload Details' }
    ];

    this.currentStep = 'UploadFileStep';
    this.steps = [
      {
        url: 'UploadFileStep',
        title: 'Upload Files'
      },
      {
        url: 'ReviewFileSummaryStep',
        title: 'Review File Summary'
      }];

    this.fileUploadService.uploadUrl = this.endpointsService.getUploadEndpoint() + this.endpointsService.getEndpoints().cdxUploadUrl;
    this.fileUploadService.statusUrl = this.endpointsService.getUploadEndpoint() + this.endpointsService.getEndpoints().cdxStatusUrl;

    if (this.apiService.getUserDetails().isExternalUser) {
      this.isInternal = false;
      this.sellerServiceNumberService.getSellerServiceNumber(this.userId).subscribe
        ((sellerServicerData: SellerServicerData[]) => {
          this.sellerServicerDataArray = sellerServicerData;
          this.sellerServicerNumber = this.apiService.getSellerServicerNumber();

          //Place all SSN's in a string
          if (this.sellerServicerDataArray[0].sellerServicerNum) {
            let tmpSSNArray = [];
            let tmpSSNParentArray = [];
            for (let i = 0; i < this.sellerServicerDataArray.length; i++) {
              if (this.sellerServicerDataArray[i].sellerServicerNum && !tmpSSNArray.includes(this.sellerServicerDataArray[i].sellerServicerNum)) {
                tmpSSNArray.push(this.sellerServicerDataArray[i].sellerServicerNum);
              }
              if (this.sellerServicerDataArray[i].sellerServicerNum && !tmpSSNParentArray.includes(this.sellerServicerDataArray[i].sellerServicerNum.substring(0, 5))) {
                tmpSSNParentArray.push(this.sellerServicerDataArray[i].sellerServicerNum.substring(0, 5));
              }
              this.sellerServicerNumber.sellerServicerNo = tmpSSNArray.join(',');
              this.sellerServicerNoParent = tmpSSNParentArray.join(',');
            }
          } else {
            this.sellerServicerNumber.sellerServicerNo = '';
            this.sellerServicerNoParent = '';
          }

          // Place corporate code
          if (this.sellerServicerDataArray[0].corporateFamily) {
            this.cfmlycode = this.sellerServicerDataArray[0].corporateFamily;
          }
          this.apiService.saveSellerServicerNumber(this.sellerServicerNumber);
          this.userDetails = this.apiService.getUserDetails();
          this.lenderName = this.apiService.getUserDetails().subscriberName;

          this.getUserInformation();
          this.acqnDetails = {
            acqnDashboardDetails: [],
            lenderNames: []
          };
          this.getAcquisitionDeals(false);
        },
          err => {
            // We don't want customer to continue if there is an error getting SSNs.
            if (err.status === 400 && err.message.contains("doesn't have access")) {
              this.router.navigate(['un-authorized']);
            } else {
              this.router.navigate(['error']);
            }
          });
    } else {
      this.isInternal = true;
      this.sellerServicerNumber = this.apiService.getSellerServicerNumber();
      this.sellerServicerNumber.sellerServicerNo = '';
      this.sellerServicerNoParent = '';
      this.apiService.saveCorporateFamily('');
      this.lenderName = "Fannie Mae";
      this.getUserInformation();
      // disable if they have READONLY roles
      if (this.roles && this.roles.length > 0) {
        this.roles.forEach(role => {
          let roleName: String = role.toString();
          if (roleName.indexOf('SG-GA6-MFDIGITIZER-UAT-ReadOnly') == 0) {
            this.disableUpload = true;
          } else if (roleName.indexOf('SG-GA6-MFDIGITIZER-PROD-ReadOnly') == 0) {
            this.disableUpload = true;
          }
        });
      }
      // enable if they have dual role (TEMPORARILY)
      if (this.roles && this.roles.length > 0) {
        this.roles.forEach(role => {
          let roleName: String = role.toString();
          if (roleName.indexOf('SG-GA6-MFDIGITIZER-UAT-ReadWrite') == 0) {
            this.disableUpload = false;
          } else if (roleName.indexOf('SG-GA6-MFDIGITIZER-PROD-ReadWrite') == 0) {
            this.disableUpload = false;
          }
        });
      }
      this.acqnDetails = {
        acqnDashboardDetails: [],
        lenderNames: []
      };
      if ((this.isAssetManager || this.isMAUser) && !this.isAdminUser) {
        this.isLoadingDueDiligence = true;
        this.getSubmittedFilesData(this.userId, "", "", this.cfmlycode, "");
      } else {
        this.getAcquisitionDeals(true);
      }
    }

    this.setShowTabs()
  }

  private setShowTabs() {
    this.showTabLenderRanking = this.isInternal;
    this.showTabAcquisitions = true;
    this.showTabDataFiles = true;
    this.showTabDataFiles = true;

    if ((this.isAssetManager || this.isMAUser) && !this.isAdminUser) {
      this.showTabLenderRanking = false;
      this.showTabAcquisitions = false;
      this.showTabDataFiles = true;
    }
  }

  public loadTabData(event) {
    if (event.index == 2 && this.isInternal) {
      //internal Data Files
      this.isLoadingDueDiligence = true;
      this.getSubmittedFilesData(this.userId, "", "", this.cfmlycode, "");
    } else if (event.index == 1 && !this.isInternal) {
      //external Data Files
      this.isLoadingDueDiligence = true;
      this.getSubmittedFilesData(this.userId, this.docWayDetailsService.mf_deal_id, this.docWayDetailsService.mf_doc_type, this.cfmlycode, this.sellerServicerNoParent);
    } else if ((event.index == 0 && !this.isInternal) || (event.index == 1 && this.isInternal)) {
      //internal and external Acquisitions
      this.acqnDetails = {
        acqnDashboardDetails: [],
        lenderNames: []
      };
      this.getAcquisitionDeals(false);
    } else if (event.index == 0 && this.isInternal) {
      //internal Lender Ranking
      this.acqnDetails = {
        acqnDashboardDetails: [],
        lenderNames: []
      };
      this.getAcquisitionDeals(true);
    }
  }

  public getUserInformation() {
    this.userInfoArray = this.dashboardService.getUserInformation();
  }

  public filterBasedOnLenderName(event, tab) {
    if (tab == 'Data Files') {
      this.dataFilesDetailsDashboardDisplay = [];
      if (event.value == '') {
        for (let i = 0; i < this.dataFilesDetails.dataFilesDashboardDetails.length; i++) {
          this.dataFilesDetailsDashboardDisplay.push(this.dataFilesDetails.dataFilesDashboardDetails[i]);
        } 
        for (let j = 0; j < this.dataFilesDetails.dataFilesDashboardDetails.length; j++) {
          if (this.dataFilesDetails.dataFilesDashboardDetails[j].lender == event.value) {
            this.dataFilesDetailsDashboardDisplay.push(this.dataFilesDetails.dataFilesDashboardDetails[j]);
          }
        }
      }
    }
  }

  public getAcquisitionDeals(isLenderRanking: boolean) {
    let startDateTemp = new Date(this.selectedStartPeriod);
    let formattedStartDate = (startDateTemp.getMonth() + 1).toString() + '/' + startDateTemp.getFullYear().toString();

    let formattedEndDate = "";
    if (this.selectedEndPeriod != "") {
      let endDateTemp = new Date(this.selectedEndPeriod);
      formattedEndDate = (endDateTemp.getMonth() + 1).toString() + '/' + endDateTemp.getFullYear().toString();
    }


    this.isLoadingDueDiligence = true;
    this.getAcquisitionData(formattedStartDate, formattedEndDate, isLenderRanking);
  }

  public getAcquisitionData(startDate: string, endDate: string, isLenderRanking: boolean) {
    this.dashboardService.getAcquisitionData(this.sellerServicerNumber.sellerServicerNo, startDate, endDate, this.isInternal).subscribe(
      (res: AcquisitionDetails) => {
        this.acqnDetails = res;

        if (this.isAssetManager)  // Need to get data to load into the table
          this.updateTable();
      },
      err => {
        this.showAlertFeedback("error", AppConstants.GENERAL_ERROR_MSG_SERVER);
        this.acqnDetails.acqnDashboardDetails = [];
        this.acqnDetails.lenderNames = [];
        this.isLoadingDueDiligence = false;
      },
      () => {
        if (this.acqnDetails.acqnDashboardDetails) {
          for (let i = 0; i < this.acqnDetails.acqnDashboardDetails.length; i++) {
            if (this.acqnDetails.acqnDashboardDetails[i].aqsnDt)
              this.acqnDetails.acqnDashboardDetails[i].aqsnDt = new Date(this.acqnDetails.acqnDashboardDetails[i].aqsnDt);
            else if (this.acqnDetails.acqnDashboardDetails[i].bkEntDt)
              this.acqnDetails.acqnDashboardDetails[i].aqsnDt = new Date(this.acqnDetails.acqnDashboardDetails[i].bkEntDt);
            if (this.acqnDetails.acqnDashboardDetails[i].reqdSubmtDt)
              this.acqnDetails.acqnDashboardDetails[i].reqdSubmtDt = new Date(this.acqnDetails.acqnDashboardDetails[i].reqdSubmtDt);
            if (this.acqnDetails.acqnDashboardDetails[i].maxSubmtDt)
              this.acqnDetails.acqnDashboardDetails[i].maxSubmtDt = new Date(this.acqnDetails.acqnDashboardDetails[i].maxSubmtDt);
          }
        }
        //default sort by date
        this.acqnDetails.acqnDashboardDetails = _.sortBy(this.acqnDetails.acqnDashboardDetails, ['aqsnDt']).reverse();
        this.isLoadingDueDiligence = false;
      }
    );
  }

  public getSubmittedFilesData(userId, dealId, reportType, cfmlycode, parentSSNList) {
    this.dashboardService.getSubmittedFilesData(userId, dealId, reportType, cfmlycode, parentSSNList, this.utils.getUserType(this.isInternal, this.isAssetManager, this.isMAUser, this.isMFOpsUser)).subscribe(
      (res: DataFilesDetails) => {
        this.dataFilesDetails = res;
      },
      err => {
        this.showAlertFeedback("error", AppConstants.GENERAL_ERROR_MSG_SERVER);
        this.dataFilesDetails.dataFilesDashboardDetails = [];
        this.dataFilesDetails.lenderNames = [];
        this.isLoadingDueDiligence = false;
      },
      () => {
        if (this.dataFilesDetails.dataFilesDashboardDetails) {
          this.dataFilesDetailsDashboardDisplay = this.dataFilesDetails.dataFilesDashboardDetails;
          for (let i = 0; i < this.dataFilesDetailsDashboardDisplay.length; i++) {
            this.dataFilesDetailsDashboardDisplay[i].submitDT = new Date(this.dataFilesDetailsDashboardDisplay[i].submitDT);
          }
        } else {
          this.dataFilesDetailsDashboardDisplay = [];
        }
        this.dataFilesLenderList = [{ label: 'All', value: '' }];
        if (this.dataFilesDetails.lenderNames) {
          for (let i = 0; i < this.dataFilesDetails.lenderNames.length; i++) {
            this.dataFilesLenderList.push({ label: this.dataFilesDetails.lenderNames[i], value: this.dataFilesDetails.lenderNames[i] });
          }
          this.dataFilesLenderList = _.sortBy(this.dataFilesLenderList, ['value']);
        } else {
          this.dataFilesLenderList = [{ label: 'All', value: '' }];
        }
        this.isLoadingDueDiligence = false;
      }
    );
  }

  public getFilesData(objectKey, validationonly) {
    this.dashboardService.getFilesData(objectKey, this.userId, this.cfmlycode, validationonly, this.utils.getUserType(this.isInternal, this.isAssetManager, this.isMAUser, this.isMFOpsUser)).subscribe(
      (uploadedFilesData: UploadedFilesData) => {
        if (uploadedFilesData) {
          this.calculateIssueSummary(uploadedFilesData.fileStatus.errorsList);
          let fileStatus = {
            "fileStatus": {
              "objectKey": uploadedFilesData.fileStatus.objectKey,
              "status": uploadedFilesData.fileStatus.status,
              "userid": uploadedFilesData.fileStatus.userid,
              "issueSummary": this.issueSummary,
              "receivedDT": uploadedFilesData.fileStatus.receivedDT == null ? "" : uploadedFilesData.fileStatus.receivedDT,
              "errorsList": uploadedFilesData.fileStatus.errorsList
            },
            "reportedFields": uploadedFilesData.reportedFields
          };
          this.persistFileStatus(validationonly, fileStatus);
        } else {
          this.showConfirmDialog("Warning", uploadedFilesData.fileStatus.objectKey.split("___")[1] + " is an invalid file and was not uploaded.", "");
        }
      },
      err => {
        //TODO: Handle getFilesData Errors
        //For now just create a mock error message and push it to uploaded files array
        let mockErrorMessage = {
          "fileStatus": {
            "objectKey": objectKey,
            "status": "Error",
            "userid": null,
            "receivedDT": "",
            "errorsList": null
          },
          "reportedFields": [
            {
              "displayName": "",
              "type": ""
            }
          ]
        };
        if (validationonly) {
          this.uploadedFilesArray.push(mockErrorMessage);
          this.hideSpinner(this.uploadedFilesArray.length, this.selectedFileCount);
          if (!this.isLoading) {
            this.uploadedFilesArray = [];
            this.currentStep = "ReviewFileSummaryStep";
          }
        } else {
          this.submittedFilesArray.push(mockErrorMessage);
          this.hideSpinner(this.submittedFilesArray.length, this.selectedFilesArray.length);
        }
        this.editFileNames();
      },
      () => {
        this.editFileNames();
        this.updateTable()
      }
    );
  }

  persistFileStatus(validationonly, fileStatus) {
    if (validationonly) {
      this.uploadedFilesArray.push(fileStatus);
      this.uploadedFilesArrayTemp.push(fileStatus);
      this.uploadedFilesArrayDisplay.push({
        "objectKey": fileStatus.fileStatus.objectKey,
        "status": fileStatus.fileStatus.status,
        "userid": fileStatus.fileStatus.userid,
        "issueSummary": fileStatus.fileStatus.issueSummary,
        "receivedDT": fileStatus.fileStatus.receivedDT == null ? "" : fileStatus.fileStatus.receivedDT,
        "errorsList": fileStatus.fileStatus.errorsList
      });
      this.hideSpinner(this.uploadedFilesArray.length, this.selectedFileCount);
      if (!this.isLoading) {
        this.currentStep = "ReviewFileSummaryStep";
      }
    } else {
      this.submittedFilesArray.push(fileStatus);
      this.hideSpinner(this.submittedFilesArray.length, this.selectedFilesArray.length);
    }
  }

  // Fix Me: TO DO: Do we need to go through entire list all the time?
  // We don't need it as we are polling one file at a time.
  editFileNames() {
    this.originalName = "";
    this.newNameSplit = [];
    this.newName = "";
    for (let i = 0; i < this.uploadedFilesArrayDisplay.length; i++) {
      this.originalName = this.uploadedFilesArrayDisplay[i].objectKey;
      if (this.originalName && this.originalName.includes("___")) {
        this.newNameSplit = this.originalName.split("___");
        this.newName = this.newNameSplit[1];
        this.uploadedFilesArrayDisplay[i].filename = this.newName;
      }
    }
  }

  updateTable() {
    this.isLoadingDueDiligence = true;
    this.getSubmittedFilesData(this.userId, this.docWayDetailsService.mf_deal_id, this.docWayDetailsService.mf_doc_type, this.cfmlycode, this.sellerServicerNoParent);
  }

  calculateIssueSummary(errorsAndWarnings) {
    this.errorCount = 0;
    this.warningCount = 0;

    let mappedErrors = _(errorsAndWarnings).mapValues("errors").value();
    let filteredMappedErrors = _.filter(mappedErrors, _.size);
    let flattenedFilteredMappedErrors = _.flatten(filteredMappedErrors);
    this.errorCount = flattenedFilteredMappedErrors.length;
    let mappedWarnings = _(errorsAndWarnings).mapValues("warnings").value();
    let filteredMappedWarnings = _.filter(mappedWarnings, _.size);
    let flattenedFilteredMappedWarnings = _.flatten(filteredMappedWarnings);
    this.warningCount = _.uniq(flattenedFilteredMappedWarnings).length;

    this.issueSummary = this.errorCount + " error(s); " + this.warningCount + " warning(s)";
  }

  showIssueSummaryPage(rowData) {
    this.rowData = rowData;
    this.showIssueSummaryPreview = true;
  }

  onSubmitSelection(event: any) {
    this.selectFilesTouched = true;
    this.selectedFilesArray = event;
  }

  onSubmitFile() {
    this.isLoading = true;
    this.submittedFilesArray = [];
    for (let i = 0; i < this.selectedFilesArray.length; i++) {
      this.getFilesData(this.selectedFilesArray[i].objectKey, false);
    }
  }

  //TODO: Remove dynamic screen sizing when ADK is updated to support modal window with UPF component
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.modalScreenHeight = window.innerHeight * 0.60;
    this.modalScreenWidth = window.innerWidth * 0.55;
    this.modalTableHeight = this.modalScreenHeight + "px";
  }

  backToUpload() {
    this.setDisplayFlex();
    this.uploadedFilesArray = [];
    this.currentStep = 'UploadFileStep';
  }

  backToReview() {
    if (this.uploadedFilesArray.length != 0)
      this.currentStep = 'ReviewFileSummaryStep';
  }

  finishAndClose() {
    //check whether files that can be submitted have been touched
    let eligibleFileCount = 0;
    for (let i = 0; i < this.uploadedFilesArrayDisplay.length; i++) {
      if (this.uploadedFilesArrayDisplay[i].status == "Ready to submit" || this.uploadedFilesArrayDisplay[0].status == "Acceptable with warnings")
        eligibleFileCount++;
    }
    if (eligibleFileCount > 0 && !this.selectFilesTouched) {
      this.finishAndCloseClicked = true;
    } else {
      this.continueSubmission();
    }
  }

  continueSubmission() {
    // Check if system is in maintenance mode, if yes, route to home page
    this.launchDarkly.getLaunchDarklyShutDown(this, this.launchDarklyCallbackFileSubmission)
  }

  hideUpload() {
    this.fileUploadService.disableUpload = false;
    this.fileUploadService.rejectedFiles = [];
    this.fileUploadService.selectedFiles = [];
    this.hideDialog(2);
  }

  showAlertFeedback(type: string, message: string) {
    this.alertFeedback = [];
    this.alertFeedback.push({ type: type, message: message });
  }

  hideDialog(num) {
    this['display' + num] = false;
    this.isLoading = false;
  }

  showConfirmDialog(header, msg, footer) {
    this.hideDialog(1);
    let fileListString = "";
    if (this.fileUploadService.rejectedFiles) {
      for (let i = 0; i < this.fileUploadService.rejectedFiles.length; i++) {
        fileListString = fileListString + this.fileUploadService.rejectedFiles[i] + '<br>';
      }
      msg = msg + '<br><br><i>' + fileListString + '</i><br>' + footer + '<br>';
    }
    this.confirmationService.confirm({
      message: msg,
      header: header,
      accept: () => {
        this.showDialog(1);
        this.hideUpload()
      },
      key: 'confirm',
      rejectVisible: false
    });
  }

  hideSpinner(value1, value2) {
    if (value1 == value2)
      this.isLoading = false;
  }

  ///////////////////////
  //FILE UPLOAD METHODS//
  ///////////////////////

  //when 'Upload Files' is clicked
  uploadFiles() {
    this.setDisplayFlex();
    this.showDialog(1);
  }

  showDialog(num, type?, message?) {
    this.alertMessage = "";
    this['display' + num] = true;
    if (message) {
      this.alertMessage = message;
    }
  }

  setDisplayFlex() {
    let dialog: HTMLElement;
    dialog = this.dialogRef.nativeElement.getElementsByClassName('ui-dialog')[0];
    dialog.style.display = 'flex';
    dialog.style.flexDirection = 'column';
  }

  //when files selected for uploading
  onSelectFile(event: any) {
     // Check if system is in maintenance mode, if yes, route to home page
     this.launchDarkly.getLaunchDarklyShutDown(this, this.launchDarklyCallbackShutdown)

    //Admin
    if (this.isAdminUser) {
      this.fileUploadService.processSelectedFiles_All(event);
      if (this.fileUploadService.disableUpload) {
        if (this.docWayDetailsService.mf_deal_id || this.docWayDetailsService.mf_doc_type) {
          this.showConfirmDialog(AppConstants.FILE_NAMING_ERROR_HEADER, AppConstants.FILE_NAMING_ERROR_MSG_ALL, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        } else if (this.fileUploadService.selectedFiles.length > AppConstants.MAX_FILE_UPLOAD_LIMIT) {
          this.showConfirmDialog(AppConstants.MAX_FILE_UPLOAD_LIMIT_HEADER, AppConstants.MAX_FILE_UPLOAD_LIMIT_MSG, "");
        } else if (this.fileUploadService.selectedFileReportType == "APPR" && this.fileUploadService.selectedFileFormat != "XML") {
          this.showConfirmDialog(AppConstants.FILE_FORMAT_ERROR_HEADER, AppConstants.FILE_FORMAT_ERROR_MSG_APPR, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        } else if (this.fileUploadService.selectedFileReportType == "APPR" || this.fileUploadService.selectedFileReportType == "BPO") {
          this.showConfirmDialog(AppConstants.FILE_NAMING_ERROR_HEADER, AppConstants.FILE_NAMING_ERROR_MSG_APPR, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        } else if (this.fileUploadService.selectedFileReportType == "BPO") {
          this.showConfirmDialog(AppConstants.FILE_NAMING_ERROR_HEADER, AppConstants.FILE_NAMING_ERROR_MSG_CRN, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        } else if (this.fileUploadService.selectedFileReportType == "PCA" || this.fileUploadService.selectedFileReportType == "ESA" ||
          this.fileUploadService.selectedFileReportType == "ZON" || this.fileUploadService.selectedFileReportType == "SRA") {
          this.showConfirmDialog(AppConstants.FILE_NAMING_ERROR_HEADER, AppConstants.FILE_NAMING_ERROR_MSG_DEALID, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        } else {
          this.showConfirmDialog(AppConstants.FILE_NAMING_ERROR_HEADER, AppConstants.FILE_NAMING_ERROR_MSG_ALL, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        }
      }
      //External, Asset Manager and MF Ops User
    } else if (!this.isInternal || this.isAssetManager || this.isMFOpsUser) {
      this.fileUploadService.processSelectedFiles_ThirdPartyReports_Appraisal(event);
      if (this.fileUploadService.disableUpload) {
        if (this.docWayDetailsService.mf_deal_id || this.docWayDetailsService.mf_doc_type) {
          this.showConfirmDialog(AppConstants.FILE_NAMING_ERROR_HEADER, AppConstants.FILE_NAMING_ERROR_MSG_DEALID, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        } else if (this.fileUploadService.selectedFiles.length > AppConstants.MAX_FILE_UPLOAD_LIMIT) {
          this.showConfirmDialog(AppConstants.MAX_FILE_UPLOAD_LIMIT_HEADER, AppConstants.MAX_FILE_UPLOAD_LIMIT_MSG, "");
        } else if (this.fileUploadService.selectedFileReportType == "BPO") {
          this.showConfirmDialog(AppConstants.FILE_TYPE_ERROR_HEADER, AppConstants.FILE_TYPE_ERROR_MSG, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        } else if (this.fileUploadService.selectedFileReportType == "APPR" && this.fileUploadService.selectedFileFormat != "XML") {
          this.showConfirmDialog(AppConstants.FILE_FORMAT_ERROR_HEADER, AppConstants.FILE_FORMAT_ERROR_MSG_APPR, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        } else if (this.fileUploadService.selectedFileReportType == "APPR") {
          this.showConfirmDialog(AppConstants.FILE_NAMING_ERROR_HEADER, AppConstants.FILE_NAMING_ERROR_MSG_APPR, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        } else if (this.fileUploadService.selectedFileReportType == "PCA" || this.fileUploadService.selectedFileReportType == "ESA" ||
          this.fileUploadService.selectedFileReportType == "ZON" || this.fileUploadService.selectedFileReportType == "SRA") {
          this.showConfirmDialog(AppConstants.FILE_NAMING_ERROR_HEADER, AppConstants.FILE_NAMING_ERROR_MSG_DEALID, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        } else {
          this.showConfirmDialog(AppConstants.FILE_NAMING_ERROR_HEADER, AppConstants.FILE_NAMING_ERROR_MSG_ALL, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        }
      }
      //M&A User
    } else if (this.isMAUser) {
      this.fileUploadService.processSelectedFiles_BPO(event);
      if (this.fileUploadService.disableUpload) {
        if (this.fileUploadService.selectedFiles.length > AppConstants.MAX_FILE_UPLOAD_LIMIT) {
          this.showConfirmDialog(AppConstants.MAX_FILE_UPLOAD_LIMIT_HEADER, AppConstants.MAX_FILE_UPLOAD_LIMIT_MSG, "");
        } else if (this.fileUploadService.selectedFileReportType == "TPR") {
          this.showConfirmDialog(AppConstants.FILE_TYPE_ERROR_HEADER, AppConstants.FILE_TYPE_ERROR_MSG, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        } else if (this.fileUploadService.selectedFileReportType == "APPR") {
          this.showConfirmDialog(AppConstants.FILE_TYPE_ERROR_HEADER, AppConstants.FILE_TYPE_ERROR_MSG, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        } else {
          this.showConfirmDialog(AppConstants.FILE_NAMING_ERROR_HEADER, AppConstants.FILE_NAMING_ERROR_MSG_CRN, AppConstants.FILE_UPLOAD_ERROR_NOTE);
        }
      }
    }

    if (this.selectedFileCount > AppConstants.MAX_FILE_UPLOAD_LIMIT) {
      this.fileUploadService.disableUpload = true;
      this.showConfirmDialog(AppConstants.MAX_FILE_UPLOAD_LIMIT_HEADER, AppConstants.MAX_FILE_UPLOAD_LIMIT_MSG, "");
    }
  }

  //when 'Start Upload' is clicked
  completeFileUpload(event: any) {
    this.selectedFileCount = event.files.length;
    this.fileUploadService.selectedFiles = [];
    this.isLoading = true;
  }

  //when files are done uploading
  onUploadFile(event: any) {
    //add files to list if upload is completed
    if (event.status == "COMPLETED") {
      this.cdxUploadedFilesList.push(event);
    }
    //confirm virus scan is completed for all files uploaded
    if (this.selectedFileCount == this.cdxUploadedFilesList.length) {
      for (let i = 0; i < this.cdxUploadedFilesList.length; i++) {
        this.getFilesData(this.cdxUploadedFilesList[i].fileUploadId + "___" + this.cdxUploadedFilesList[i].fileName, true);
      }
      this.cdxUploadedFilesList = [];
    }
  }

  printScreen() {
    let printContents, printWindow;
    printContents = document.getElementById('print-preview-lender-ranking')?.innerHTML;
    printWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    printWindow.document.s
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Lender Ranking Summary</title>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    printWindow.document.close();
  }

  getDropDownDates() {
    let date = new Date();
    let months = [];
    let currentDate = new Date();

    for (let i = 0; i < 12; i++) {
      if ((this.monthNames[date.getMonth()] + ' ' + date.getFullYear()) == AppConstants.START_PERIOD_DD) {
        months.push(this.monthNames[date.getMonth()] + ' ' + date.getFullYear());
        break;
      } else {
        months.push(this.monthNames[date.getMonth()] + ' ' + date.getFullYear());
        date.setMonth(date.getMonth() - 1);
      }
    }
    for (let i = 0; i < months.length; i++) {
      this.overviewDropdownMenu.push({ label: months[i], value: months[i] });
    }
    this.selectedOverviewPeriod = this.monthNames[currentDate.getMonth()] + " " + currentDate.getFullYear();
  }

  calculateMonths(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  getStartPeriodDates() {
    this.selectStartPeriod = [];

    let date = new Date();
    let months = [];
    let futureDate = new Date();

    futureDate.setFullYear(futureDate.getFullYear() + 1);
    for (let i = 0; i < 12; i++) {
      months.push(this.monthNames[futureDate.getMonth()] + ' ' + futureDate.getFullYear());
      futureDate.setMonth(futureDate.getMonth() - 1);
    }

    for (let i = 0; i <= this.monthDiff; i++) {
      if ((this.monthNames[date.getMonth()] + ' ' + date.getFullYear()) == AppConstants.START_PERIOD_DD) {
        months.push(this.monthNames[date.getMonth()] + ' ' + date.getFullYear());
        break;
      } else {
        months.push(this.monthNames[date.getMonth()] + ' ' + date.getFullYear());
        date.setMonth(date.getMonth() - 1);
      }
    }
    for (let i = 0; i < months.length; i++) {
      this.selectStartPeriod.push({ label: months[i], value: months[i] });
    }
    _.reverse(this.selectStartPeriod);
  }

  getEndPeriodDates(onload: boolean, isLenderRanking: boolean) {
    this.selectEndPeriod = [];
    this.alertFeedback = [];

    if (new Date(this.selectedStartPeriod) >= new Date(this.selectedEndPeriod))
      this.selectedEndPeriod = this.selectedStartPeriod;

    if (this.selectedEndPeriod != "" && !onload)
      this.getAcquisitionDeals(isLenderRanking);

    let date = new Date();
    let months = [];
    let startDate = new Date();
    let futureDate = new Date();

    if (this.selectedStartPeriod != "")
      startDate = new Date(this.selectedStartPeriod);
    else
      startDate = new Date(AppConstants.START_PERIOD_DD);

    futureDate.setFullYear(futureDate.getFullYear() + 1);

    if (new Date(this.selectedStartPeriod) <= new Date()) {
      this.monthDiffFutureDate = this.calculateMonths(new Date(), futureDate);
    } else {
      this.monthDiffFutureDate = this.calculateMonths(startDate, futureDate);
    }
    //add future months
    for (let i = 0; i <= this.monthDiffFutureDate; i++) {
      months.push(this.monthNames[futureDate.getMonth()] + ' ' + futureDate.getFullYear());
      futureDate.setMonth(futureDate.getMonth() - 1);
    }

    //add past months
    if (new Date(this.selectedStartPeriod) <= new Date()) {
      for (let i = 0; i <= this.monthDiff; i++) {
        if ((this.monthNames[date.getMonth()] + ' ' + date.getFullYear()) == (this.monthNames[startDate.getMonth()]
          + ' ' + startDate.getFullYear())) {
          months.push(this.monthNames[date.getMonth()] + ' ' + date.getFullYear());
          break;
        } else {
          months.push(this.monthNames[date.getMonth()] + ' ' + date.getFullYear());
          date.setMonth(date.getMonth() - 1);
        }
      }
    }
    months = Array.from(new Set(months));
    for (let i = 0; i < months.length; i++) {
      this.selectEndPeriod.push({ label: months[i], value: months[i] });
    }
    this.selectEndPeriod.push({ label: "", value: "" });
    _.reverse(this.selectEndPeriod)
  }

  onRefreshAcqnDetails(event: { startDate: string; endDate: string; isLenderRanking: boolean; }) {
    this.selectedStartPeriod = event.startDate;
    this.selectedEndPeriod = event.endDate;
    this.getAcquisitionDeals(event.isLenderRanking);
  }

  onGetEndPeriodDates(event: { startDate: string; endDate: string; onload: any; isLenderRanking: any; }) {
    this.selectedStartPeriod = event.startDate;
    this.selectedEndPeriod = event.endDate;
    let onload = event.onload;
    let isLenderRanking = event.isLenderRanking;
    this.getEndPeriodDates(onload, isLenderRanking);
    this.getAcquisitionDeals(isLenderRanking);
  }

  initLaunchDarkly() {
    this.launchDarkly.getLaunchDarklyNotification(this, this.launchDarklyCallback)
  }

  launchDarklyCallback(systemNotificationFlagValue, caller) {
    caller.systemNotificationFlagValue = systemNotificationFlagValue;
    console.log('LaunchDarkly flag systemNotificationFlagValue = ', caller.systemNotificationFlagValue);
    if (Object.keys(caller.systemNotificationFlagValue).length > 0) {
      caller.showSystemNotification = true;
    }
  }

  launchDarklyCallbackShutdown(systemDownFlagValue: boolean, caller) {
    caller.systemDownFlagValue = systemDownFlagValue;
    console.log('LaunchDarkly flag systemDownFlagValue = ', caller.systemDownFlagValue);
    if (Object.keys(caller.systemDownFlagValue).length > 0) {
      caller.router.navigate(['/'], { skipLocationChange: true });
    }
  }

  launchDarklyCallbackFileSubmission(systemDownFlagValue: boolean, caller) {
    caller.systemDownFlagValue = systemDownFlagValue;
    console.log('LaunchDarkly flag systemDownFlagValue = ', caller.systemDownFlagValue);
    if (Object.keys(caller.systemDownFlagValue).length > 0) {
      caller.router.navigate(['/'], { skipLocationChange: true });
    } else {
      caller.onSubmitFile();
      caller.currentStep = "UploadFileStep";
      caller.hideDialog(1);
      caller.uploadedFilesArray = [];
      caller.uploadedFilesArrayTemp = [];
      caller.uploadedFilesArrayDisplay = [];
      caller.selectedFilesArray = [];
      caller.updateTable();
    }
  }
}
